<template>
	<div class="sys-app">
		<div class="header">
			<a-breadcrumb style="margin-bottom: 20px">
				<a-breadcrumb-item>系统配置</a-breadcrumb-item>
				<a-breadcrumb-item>应用信息</a-breadcrumb-item>
			</a-breadcrumb>
			<a-row>
				<a-col :span="18">
					<!-- <a-button type="primary" @click="appCreateModalVisible = true">新增</a-button> -->
				</a-col>
				<a-col :span="6">
					<a-input-search v-model="dataSourceSearchInput" placeholder="请输入任何想搜索的内容" allowClear
						@search="handleDataSourceSearch" />
				</a-col>
			</a-row>
		</div>
		<div class="body">
			<a-table tableLayout="fixed" rowKey="id" size="small" bordered :data-source="getCurrentDataSourceList"
				:pagination="paginationOptions" :scroll="{ x: true }"
				@change="(pagination) => {paginationOptions.current = pagination.current;paginationOptions.pageSize = pagination.pageSize;}">
				<a-table-column title="序号" data-index="index" align="center">
					<template slot-scope="text, record, index">
						<span>{{(paginationOptions.current - 1) * paginationOptions.pageSize + parseInt(index) + 1}}</span>
					</template>
				</a-table-column>
				<a-table-column title="ID" data-index="id" />
				<a-table-column title="名称" data-index="name" />
				<a-table-column title="密钥" data-index="appKey" />
				<a-table-column title="备注" data-index="remark" />
				<!-- <a-table-column title="创建时间" data-index="createTime" /> -->
				<!-- <a-table-column title="创建人" data-index="createBy" /> -->
				<a-table-column title="状态" data-index="status" fixed="right">
					<template slot-scope="text, record">
						<a-switch checked-children="启用" un-checked-children="禁用" :checked="text === 1"
							@change="handleStatusSwitchChange(record)" />
					</template>
				</a-table-column>
				<a-table-column title="操作" data-index="appVersion" fixed="right">
					<template slot-scope="text, record">
						<a-button type="link" size="small"
							@click="dataSourceCurrent = record;appVersionModalVisible = true;">版本管理</a-button>
					</template>
				</a-table-column>
				<!-- <a-table-column title="操作" data-index="operation">
          <template slot-scope="text">
            <a-button type="link" size="small">修改</a-button>
          </template>
        </a-table-column> -->
			</a-table>
		</div>
		<div class="footer"></div>

		<a-modal v-model="appCreateModalVisible" title="请填写以下信息" closable :footer="null">
			<app-create-form @onSubmit="handleAppCreateFormSubmit" />
		</a-modal>

		<a-modal v-model="appVersionModalVisible" title="应用版本信息" closable :footer="null"
			:width="screenSpec.width - 2 * 100">
			<app-version-table :appId="dataSourceCurrent.id" :app="dataSourceCurrent" />
		</a-modal>
	</div>
</template>

<script>
	import PaginationOptions from "@/options/pagination.js";
	import FiltrationOptions from "@/options/filtration.js";
	import SysAppApi from "@/api/sys/sys-app.js";
	import AppCreateForm from "./components/AppCreateForm.vue";
	import AppVersionTable from "./components/AppVersionTable.vue";
	import {
		mapState
	} from "vuex";

	export default {
		name: "SysApp",
		components: {
			AppCreateForm,
			AppVersionTable
		},
		data() {
			return {
				paginationOptions: PaginationOptions,
				filtrationOptions: FiltrationOptions,
				dataSourceList: [],
				dataSourceCurrent: {},
				dataSourceSearchInput: "",
				dataSourceSearchList: [],
				dataSourceSearchValid: false,
				appCreateModalVisible: false,
				appVersionModalVisible: false,
			};
		},
		computed: {
			...mapState({
				screenSpec: (state) => state.screenSpec,
			}),
			getCurrentDataSourceList: function() {
				return this.dataSourceSearchValid ?
					this.dataSourceSearchList :
					this.dataSourceList;
			},
		},
		watch: {
			dataSourceSearchInput: function(val) {
				if (val === null || val === "") {
					this.dataSourceSearchList = [];
					this.dataSourceSearchValid = false;
				}
			},
		},
		mounted: function() {
			this.fetchAppList();
		},
		methods: {
			fetchAppList: function() {
				SysAppApi.listAll().then((response) => {
					if (response.status === "SUCCESS") {
						this.dataSourceList = response.body;
					} else {
						this.dataSourceList = [];
					}
				});
			},
			handleTableChange(pagination) {
				this.paginationOptions.current = pagination.current;
				this.paginationOptions.pageSize = pagination.pageSize;
			},
			handleDataSourceSearch: function(value) {
				if (value === null || value === "") {
					this.dataSourceSearchList = [];
					this.dataSourceSearchValid = false;
					return;
				}
				this.dataSourceSearchList = this.dataSourceList.filter((item) => {
					return JSON.stringify(item).indexOf(value.trim()) !== -1;
				});
				this.dataSourceSearchValid = true;
			},
			handleAppCreateFormSubmit: function(response) {
				this.appCreateModalVisible = false;
				if (response && response.status === "SUCCESS") {
					this.fetchAppList();
				}
			},
			handleStatusSwitchChange: function(record) {
				let status = record.status === 0 ? 1 : 0;
				SysAppApi.updateStatus(record.id, status).then((response) => {
					if (response.status === "SUCCESS") {
						this.$message.success("更新成功");
						this.fetchAppList();
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.header {
		margin-bottom: 20px;
	}
</style>
