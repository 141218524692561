<template>
  <div class="app-version-create-form">
    <a-form
      layout="horizontal"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 14 }"
    >
      <a-form-item label="应用名称">
        <a-input v-model="app.name" placeholder="请填写名称" disabled />
      </a-form-item>
      <a-form-item label="版本类型">
        <a-input v-model="type" placeholder="请填写版本类型" />
      </a-form-item>
      <a-form-item label="版本编号">
        <a-input v-model="number" placeholder="请填写版本编号" />
      </a-form-item>
      <a-form-item label="版本介绍">
        <a-input v-model="introduction" placeholder="请填写版本介绍" />
      </a-form-item>
      <a-form-item label="备注">
        <a-input v-model="remark" placeholder="请填写备注" />
      </a-form-item>
      <!-- <a-form-item label="状态">
        <a-switch
          checked-children="启用"
          un-checked-children="禁用"
          @change="
            (checked) => {
              this.status = checked;
            }
          "
        />
      </a-form-item>
      <a-form-item label="强制更新">
        <a-switch
          checked-children="启用"
          un-checked-children="禁用"
          @change="
            (checked) => {
              this.required = checked;
            }
          "
        />
      </a-form-item> -->
      <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
        <a-button type="primary" html-type="submit" @click="onSubmit">
          提交
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import SysAppVersionApi from "@/api/sys/sys-app-version.js";

export default {
  name: "AppVersionCreateForm",
  props: ["app"],
  data() {
    return {
      type: "",
      number: "",
      introduction: "",
      status: 0,
      required: 0,
      remark: "",
    };
  },
  mounted: function () {},
  methods: {
    onSubmit: function () {
      const payload = {
        appId: this.app.id,
        type: this.type,
        number: this.number,
        introduction: this.introduction,
        status: this.status ? 1 : 0,
        required: this.required ? 1 : 0,
        remark: this.remark,
      };
      SysAppVersionApi.create(payload).then((response) => {
        if (response.status === "SUCCESS") {
          this.$message.success(response.message);
          this.$emit("onSubmit", response);
          Object.assign(this.$data, this.$options.data());
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
