<template>
	<div class="app-version-table">
		<div class="header">
			<a-row>
				<a-col :span="18">
					<a-button type="primary" @click="appVersionCreateModalVisible = true">新增</a-button>
				</a-col>
				<a-col :span="6"> </a-col>
			</a-row>
		</div>
		<div class="body">
			<a-table rowKey="id" size="small" bordered :data-source="dataSourceList" :pagination="paginationOptions"
				:scroll="{ x: true }" @change="
          (pagination) => {
            paginationOptions.current = pagination.current;
            paginationOptions.pageSize = pagination.pageSize;
          }
        ">
				<a-table-column title="序号" data-index="index" align="center">
					<template slot-scope="text, record, index">
						<span>{{
              (paginationOptions.current - 1) * paginationOptions.pageSize +
              parseInt(index) +
              1
            }}</span>
					</template>
				</a-table-column>
				<a-table-column title="ID" data-index="id" />
				<a-table-column title="类型" data-index="type" />
				<a-table-column title="编号" data-index="number" />
				<a-table-column title="介绍" data-index="introduction" />
				<a-table-column title="APK下载地址" data-index="apkUrl" width="200px" />
				<a-table-column title="备注" data-index="remark" />
				<a-table-column title="状态" data-index="status">
					<template slot-scope="text, record">
						<a-switch checked-children="启用" un-checked-children="禁用" :checked="text === 1"
							@change="handleStatusSwitchChange(record)" />
					</template>
				</a-table-column>
				<a-table-column title="强制更新" data-index="required">
					<template slot-scope="text, record">
						<a-switch checked-children="启用" un-checked-children="禁用" :checked="text === 1"
							@change="handleRequiredSwitchChange(record)" />
					</template>
				</a-table-column>
				<a-table-column title="操作" data-index="operation">
					<template slot-scope="text, record">
						<a-upload :data="record" :custom-request="handleUpload" :showUploadList="false">
							<a-button type="link" size="small">APK上传</a-button>
						</a-upload>
						<a-popconfirm title="是否确定删除此版本？" ok-text="是" cancel-text="否"
							@confirm="handleDataSourceDelete(record)">
							<a-button type="link" size="small">删除</a-button>
						</a-popconfirm>
					</template>
				</a-table-column>
			</a-table>
		</div>
		<div class="footer"></div>

		<a-modal v-model="appVersionCreateModalVisible" title="请填写以下信息" closable :footer="null">
			<app-version-create-form :app="app" @onSubmit="handleAppVersionCreateFormSubmit"></app-version-create-form>
		</a-modal>
	</div>
</template>

<script>
	import PaginationOptions from "@/options/pagination.js";
	import FiltrationOptions from "@/options/filtration.js";
	import SysAppVersionApi from "@/api/sys/sys-app-version.js";
	import AppVersionCreateForm from "./AppVersionCreateForm.vue";

	export default {
		name: "AppVersionTable",
		components: {
			AppVersionCreateForm
		},
		props: ["appId", "app"],
		data() {
			return {
				paginationOptions: PaginationOptions,
				filtrationOptions: FiltrationOptions,
				appVersionCreateModalVisible: false,
				dataSourceList: [],
				dataSourceCurrent: {},
				uploadFileList: [],
			};
		},
		computed: {},
		watch: {
			appId: function(val, oldVal) {
				this.fetchAppVersionList();
			},
		},
		mounted: function() {
			this.fetchAppVersionList();
		},
		methods: {
			fetchAppVersionList: function() {
				SysAppVersionApi.listByAppId(this.appId).then((response) => {
					if (response.status === "SUCCESS") {
						this.dataSourceList = response.body;
						if (this.dataSourceList.length === 0) this.$message.success("无数据");
					} else {
						this.dataSourceList = [];
					}
				});
			},
			handleAppVersionCreateFormSubmit: function(response) {
				this.appVersionCreateModalVisible = false;
				if (response && response.status === "SUCCESS") {
					this.fetchAppVersionList();
				}
			},
			handleStatusSwitchChange: function(record) {
				let status = record.status === 0 ? 1 : 0;
				SysAppVersionApi.updateStatus(record.id, status).then((response) => {
					if (response.status === "SUCCESS") {
						this.$message.success("更新成功");
						this.fetchAppVersionList();
					}
				});
			},
			handleRequiredSwitchChange: function(record) {
				let required = record.required === 0 ? 1 : 0;
				SysAppVersionApi.updateRequired(record.id, required).then((response) => {
					if (response.status === "SUCCESS") {
						this.$message.success("更新成功");
						this.fetchAppVersionList();
					}
				});
			},
			handleUpload: function(payload) {
				const formData = new FormData();
				formData.append("id", payload.data.id);
				formData.append("apkFile", payload.file);
				SysAppVersionApi.uploadApkFile(formData).then((response) => {
					if (response.status === "SUCCESS") {
						this.$message.success("上传成功");
						this.fetchAppVersionList();
					}
				});
			},
			handleDataSourceDelete: function(record) {
				SysAppVersionApi.deleteById(record.id).then((response) => {
					if (response.status === "SUCCESS") {
						this.$message.success("删除成功！");
						this.fetchAppVersionList();
					}
				});
			}
		},
	};
</script>

<style lang="scss" scoped>
	.header {
		margin-bottom: 20px;
	}
</style>
