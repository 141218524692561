<template>
  <div class="app-create-form">
    <a-form
      layout="horizontal"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 14 }"
    >
      <a-form-item label="名称">
        <a-input v-model="name" placeholder="请填写名称" />
      </a-form-item>
      <a-form-item label="备注">
        <a-input v-model="remark" placeholder="请填写备注" />
      </a-form-item>
      <!-- <a-form-item label="状态">
        <a-switch
          checked-children="启用"
          un-checked-children="禁用"
          :default-checked="status === 1"
          @change="
            (checked) => {
              this.status = checked;
            }
          "
        />
      </a-form-item> -->
      <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
        <a-button type="primary" html-type="submit" @click="onSubmit">
          提交
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import SysAppApi from "@/api/sys/sys-app.js";

export default {
  name: "AppCreateForm",
  data() {
    return {
      name: "",
      status: 0,
      remark: "",
    };
  },
  mounted: function () {},
  methods: {
    onSubmit: function () {
      const payload = {
        name: this.name,
        status: this.status ? 1 : 0,
        remark: this.remark,
      };
      SysAppApi.create(payload).then((response) => {
        if (response.status === "SUCCESS") {
          this.$message.success(response.message);
          this.$emit("onSubmit", response);
          Object.assign(this.$data, this.$options.data());
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
